import React, { useMemo, useState } from "react";
import { Button, Typography } from "antd";
import QPageLayout from "components/layout/QPageLayout";
import { useSchedules, useTransaction } from "hooks/recurring_transactions";
import { BreadcrumbItemType } from "antd/es/breadcrumb/Breadcrumb";
import { ArrowLeftOutlined } from "@ant-design/icons";
import  RTCardsInfo from "components/tenant/recurring_transactions/show/RTInfoCards";
import RelatedDocs from "components/tenant/recurring_transactions/show/RelatedDocs";
import { TableProps } from "antd/lib";
import SchedulesTable from "components/tenant/recurring_transactions/show/SchedulesTable";
import { ScheduleType } from "types/recurring_transactions";
import { useTranslation } from "react-i18next";

const translationTerms = {
  indexTitle: "recurring_transactions.title",
  title: "recurring_transactions.show.title",
  backBtn: "recurring_transactions.show.back",
  Invoice: "activerecord.commercial_document.invoice",
  Bill: "activerecord.commercial_document.bill",
  SimpleBill: "activerecord.commercial_document.simple_bill",
};

const PAGESIZE = 10;

export default function ShowRT() {
  const currentURL = window.location.href;

  const ID = useMemo(() => {
    const match = currentURL.match(/recurring_transactions\/(\d+)/);
    return match ? Number(match[1]) : null;
  }, [currentURL]);

  if (!ID) {
    return null;
  }

  const [RTQuerySearch, setRTQuerySearch] = useState<Record<string, string>>({
    page: "1",
  });

  const [upcomingQuerySearch, setUpcomingQuerySearch] = useState<
    Record<string, string>
  >({ per_page: PAGESIZE.toString(), page: "1" });

  const [passedQuerySearch, setpassedQuerySearch] = useState<
    Record<string, string>
  >({ per_page: PAGESIZE.toString(), page: "1" });

  const { t, i18n } = useTranslation();

  const { data: RTData, isLoading: isRTLInfoLoading } = useTransaction(
    ID,
    new URLSearchParams(RTQuerySearch)
  );

  const { passedSchedulesQuery, upcomingSchedulesQuery } = useSchedules(ID, {
    passed: passedQuerySearch,
    upcoming: upcomingQuerySearch,
  });

  const RTInfo = RTData?.recurring_transaction;

  const breadCrumbData = useBreadcrumb(
    RTInfo?.[i18n.language === "ar" ? "ar_name" : "en_name"]
  );

  const anyFailed = useMemo(() => {
    return passedSchedulesQuery?.data?.schedules.some(
      (record) => record.status === "failed"
    );
  }, [passedSchedulesQuery?.data]);

  const pageChangeHandler = (
    setQuery: React.Dispatch<any>
  ): TableProps["onChange"] => {
    return (pagination, _, __, { action }) => {
      if (action === "paginate") {
        setQuery((prev: Record<string, string>) => ({
          ...prev,
          page: pagination.current.toString(),
        }));
      }
    };
  };

  return (
    <QPageLayout
      title={t(translationTerms.title)}
      breadCrumbData={breadCrumbData}
      actionBtns={[
        <Button
          key={"backBTN"}
          type="primary"
          href="/tenant/recurring_transactions"
          icon={<ArrowLeftOutlined />}
        >
          {t(translationTerms.backBtn)}
        </Button>,
      ]}
    >
      <RTCardsInfo RTInfo={RTInfo} isLoading={isRTLInfoLoading} />
      <RelatedDocs
        isLoading={isRTLInfoLoading}
        pageSize={RTData?.pagination.per_page}
        totalDocs={RTData?.pagination.total_entries}
        onChange={pageChangeHandler(setRTQuerySearch)}
        documents={RTData?.documents}
        documentType={RTInfo?.document_type}
      />
      <SchedulesTable
        RTStatus={RTInfo?.status}
        documentType={RTInfo?.document_type}
        onChange={pageChangeHandler(setUpcomingQuerySearch)}
        schedulesData={upcomingSchedulesQuery.data?.schedules}
        isLoading={upcomingSchedulesQuery.isLoading}
        type={ScheduleType.UPCOMMING}
        pageSize={PAGESIZE}
        anyFailed={anyFailed}
        totalEntries={
          upcomingSchedulesQuery.data &&
          upcomingSchedulesQuery.data.meta.total_pages * PAGESIZE
        }
        currentPage={Number(upcomingQuerySearch.page)}
      />
      <SchedulesTable
        RTStatus={RTInfo?.status}
        documentType={RTInfo?.document_type}
        onChange={pageChangeHandler(setpassedQuerySearch)}
        schedulesData={passedSchedulesQuery.data?.schedules}
        isLoading={passedSchedulesQuery.isLoading}
        type={ScheduleType.PASSED}
        anyFailed={anyFailed}
        pageSize={PAGESIZE}
        totalEntries={
          passedSchedulesQuery.data &&
          passedSchedulesQuery.data.meta.total_pages * PAGESIZE
        }
      />
    </QPageLayout>
  );
}

const useBreadcrumb = (RTName: string | undefined): BreadcrumbItemType[] => {
  const { t } = useTranslation();

  return [
    {
      title: (
        <Typography.Link href="/tenant/recurring_transactions">
          {t(translationTerms.indexTitle)}
        </Typography.Link>
      ),
    },
    {
      title: RTName || "",
    },
  ];
};
